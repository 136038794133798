import { useFormikContext } from 'formik';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { bodySize, breakpoints, globalStyles } from '../../../constants';
import { Label, P } from '../../typography';

const FormikTextareaInput = ({ name, label, placeholder, rows, maxLength }) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext();
  const hasError = touched[name] && errors[name];
  const error = hasError ? errors[name] : null;

  return (
    <>
      <InputLabel as="label" color="greyPrimary" mb="0.2rem" htmlFor={name}>
        {label}
      </InputLabel>
      <TextArea
        id={name}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholder}
        rows={rows}
        hasError={hasError}
        value={get(values, name, '')}
        maxLength={maxLength}
      />
      {error && (
        <P variant={3} color="errorPrimary" mt="0.5rem">
          {error}
        </P>
      )}
    </>
  );
};

const InputLabel = styled(Label)`
  font-weight: normal;
`;

const TextArea = styled.textarea.attrs(({ theme, hasError, disabled, value }) => ({
  borderColor: () => {
    if (disabled) {
      return theme.colors.grey100;
    }

    if (hasError) {
      return theme.colors.errorPrimary;
    }

    if (value) {
      return theme.colors.successPrimary;
    }

    return theme.colors.grey300;
  },
}))`
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ borderColor }) => borderColor};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.grey300 : theme.colors.black)};
  font-family: ${globalStyles.baseFontFamily};
  font-size: ${bodySize.L3M2S1};
  padding: 0.5rem;
  resize: none;
  width: 100%;

  ::placeholder {
    color: ${({ theme }) => theme.colors.grey300};
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: ${bodySize.L2M1Sx};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${bodySize.L1MxSx};
  }
`;

FormikTextareaInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rows: PropTypes.node,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
};

FormikTextareaInput.defaultProps = {
  label: '',
  rows: 3,
  placeholder: undefined,
  maxLength: null,
};

export { FormikTextareaInput };
