import { useFormikContext } from 'formik';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { TimePicker } from '../time-picker';

const FormikTimePicker = ({ autoFocus, name, placeholder, label, format, showSecond }) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();

  const value = get(values, name);

  const handleChange = date => setFieldValue(name, date);
  const handleBlur = blur => setFieldTouched(name, blur);

  return (
    <TimePicker
      value={value}
      autoFocus={autoFocus}
      errorMessage={errors[name]}
      isError={!!touched[name] && !!errors[name]}
      label={label}
      name={name}
      format={format}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      showSecond={showSecond}
      validateOnBlur
    />
  );
};

FormikTimePicker.propTypes = {
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  showSecond: PropTypes.bool,
};

FormikTimePicker.defaultProps = {
  autoFocus: false,
  label: null,
  format: null,
  placeholder: null,
  showSecond: false,
};

export { FormikTimePicker };
