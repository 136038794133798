import { useFormikContext } from 'formik';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';

import { SelectInput } from '../select-input';

const FormikSelectInput = ({ autoFocus, disabled, name, placeholder, label, onFocus, options, width, error }) => {
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext();

  const value = values[name] || '';

  return (
    <SelectInput
      disabled={disabled}
      value={value}
      autoFocus={autoFocus}
      errorMessage={errors[name] || error}
      isError={(!!touched[name] && !!errors[name]) || !!error}
      label={label}
      name={name}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={handleBlur}
      options={options}
      placeholder={placeholder}
      width={width}
    />
  );
};

FormikSelectInput.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, text: PropTypes.string })).isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.string,
};

FormikSelectInput.defaultProps = {
  autoFocus: false,
  disabled: false,
  error: '',
  label: null,
  onFocus: noop,
  placeholder: null,
  width: '100%',
};

export { FormikSelectInput };
