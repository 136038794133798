import { INPUT_AUTOCOMPLETE, INPUT_TYPE } from '@nandosaus/constants';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { TextInput } from '../text-input';

const FormikTextInput = ({
  autoComplete,
  name,
  type,
  label,
  placeholder,
  required,
  maxLength,
  disabled,
  autoFocus,
}) => {
  const [field, meta] = useField(name);

  return (
    <TextInput
      {...field}
      autoComplete={autoComplete}
      type={type}
      errorMessage={meta.error}
      isError={!!meta.touched && !!meta.error}
      label={label}
      placeholder={placeholder}
      required={required}
      maxLength={maxLength}
      disabled={disabled}
      autoFocus={autoFocus}
    />
  );
};

FormikTextInput.propTypes = {
  autoComplete: PropTypes.oneOf([...Object.values(INPUT_AUTOCOMPLETE), 'off']),
  autoFocus: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf([
    INPUT_TYPE.CREDIT_CARD,
    INPUT_TYPE.EMAIL,
    INPUT_TYPE.EXPIRY_DATE,
    INPUT_TYPE.NUMBER,
    INPUT_TYPE.NUMERIC,
    INPUT_TYPE.PASSWORD,
    INPUT_TYPE.PHONE,
    INPUT_TYPE.TEXT,
  ]),
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
};

FormikTextInput.defaultProps = {
  autoComplete: 'off',
  autoFocus: false,
  type: INPUT_TYPE.TEXT,
  placeholder: null,
  label: null,
  required: false,
  maxLength: null,
  disabled: false,
};

export { FormikTextInput };
