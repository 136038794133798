import 'rc-time-picker/assets/index.css';
import { Dayjs } from 'dayjs';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import RcTimePicker from 'rc-time-picker';
import styled, { css } from 'styled-components';

import { Label, P } from '../../typography';

const TimePicker = ({ errorMessage, name, label, isError, showSecond, onChange, onBlur, format, value }) => (
  <>
    <InputLabel color="greyPrimary" mb="0.2rem">
      {label}
    </InputLabel>
    <StyledTimePicker
      name={name}
      showSecond={showSecond}
      onChange={onChange}
      onOpen={onBlur}
      onClose={onBlur}
      allowEmpty={false}
      format={format}
      value={value}
      isError={isError}
      use12Hours
    />
    {isError && (
      <P variant={3} color="errorPrimary" mt="0.5rem">
        {errorMessage}
      </P>
    )}
  </>
);

const InputLabel = styled(Label)`
  font-weight: normal;
`;

const StyledTimePicker = styled(RcTimePicker)`
  width: 100%;

  .rc-time-picker-input {
    color: black;
    border-color: ${({ theme, isError, value }) => {
      if (isError) {
        return theme.colors.errorPrimary;
      }

      if (value) {
        return theme.colors.successPrimary;
      }

      return theme.colors.grey300;
    }};

    ${({ isError }) =>
      isError &&
      css`
        background-color: ${({ theme }) => theme.colors.error50};
      `}
  }
`;

TimePicker.propTypes = {
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showSecond: PropTypes.bool,
  format: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.instanceOf(Dayjs),
};

TimePicker.defaultProps = {
  showSecond: false,
  format: 'h:mm a',
  onChange: noop,
  onBlur: noop,
  errorMessage: null,
  isError: false,
  value: null,
};

export { TimePicker };
