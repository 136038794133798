import { useFormikContext } from 'formik';
import get from 'lodash/get';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';

import { DateTimeInput } from '../date-time-input';

const FormikDateTimeInput = ({
  autoFocus,
  disabled,
  name,
  placeholder,
  label,
  format,
  onFocus,
  enableDate,
  enableTime,
}) => {
  const { values, errors, touched, setFieldValue, handleBlur } = useFormikContext();

  const value = get(values, name);

  const handleChange = date => {
    setFieldValue(name, date);
  };

  return (
    <DateTimeInput
      value={value}
      autoFocus={autoFocus}
      disabled={disabled}
      errorMessage={errors[name]}
      isError={!!touched[name] && !!errors[name]}
      label={label}
      name={name}
      format={format}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={handleBlur}
      placeholder={placeholder}
      enableTime={enableTime}
      enableDate={enableDate}
    />
  );
};

FormikDateTimeInput.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  format: PropTypes.string,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  enableDate: PropTypes.bool,
  enableTime: PropTypes.bool,
};

FormikDateTimeInput.defaultProps = {
  autoFocus: false,
  disabled: false,
  label: null,
  format: null,
  onFocus: noop,
  placeholder: null,
  enableDate: true,
  enableTime: true,
};

export { FormikDateTimeInput };
