import configure from 'react-widgets/lib/configure';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData); // use localeData plugin for dayjs

if (typeof dayjs !== 'function') throw new TypeError('You must provide a valid dayjs object');

const localField = typeof dayjs().locale === 'function' ? 'locale' : 'lang';
const hasLocaleData = !!dayjs.localeData;

if (!hasLocaleData)
  throw new TypeError(
    'The dayjs localizer depends on the `localeData` api, please provide a dayjs object that includes it.'
  );

function getDayjs(culture, value, format) {
  return culture ? dayjs(value, format, true)[localField](culture) : dayjs(value, format, true);
}

function endOfDecade(date) {
  return dayjs(date)
    .add(10, 'year')
    .add(-1, 'millisecond')
    .toDate();
}

function endOfCentury(date) {
  return dayjs(date)
    .add(100, 'year')
    .add(-1, 'millisecond')
    .toDate();
}

export default function dayjsLocalizer() {
  const localizer = {
    formats: {
      date: 'L',
      time: 'LT',
      default: 'lll',
      header: 'MMMM YYYY',
      footer: 'MMMM D, YYYY',
      weekday: 'dd',
      dayOfMonth: 'DD',
      month: 'MMM',
      year: 'YYYY',

      // eslint-disable-next-line no-shadow
      decade(date, culture, localizer) {
        return `${localizer.format(date, 'YYYY', culture)} - ${localizer.format(endOfDecade(date), 'YYYY', culture)}`;
      },

      // eslint-disable-next-line no-shadow
      century(date, culture, localizer) {
        return `${localizer.format(date, 'YYYY', culture)} - ${localizer.format(endOfCentury(date), 'YYYY', culture)}`;
      },
    },

    firstOfWeek(culture) {
      return dayjs.localeData(culture).firstDayOfWeek();
    },

    parse(value, format, culture) {
      if (!value) return null;
      const d = getDayjs(culture, value, format);
      if (d.isValid()) return d.toDate();
      return null;
    },

    format(value, format, culture) {
      return getDayjs(culture, value).format(format);
    },
  };

  configure.setDateLocalizer(localizer);
}
