import { get } from 'lodash';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { ToggleSwitch } from '../toggle-switch';

const FormikToggleSwitch = ({ name, label, hasIcons, ...rest }) => {
  const { values, setFieldValue, handleBlur } = useFormikContext();

  return (
    <ToggleSwitch
      {...rest}
      hasIcons={hasIcons}
      name={name}
      label={label}
      checked={get(values, name, false)}
      onBlur={handleBlur}
      onChange={checked => setFieldValue(name, checked)}
    />
  );
};

FormikToggleSwitch.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  hasIcons: PropTypes.bool,
};

FormikToggleSwitch.defaultProps = { label: null, hasIcons: false };

export { FormikToggleSwitch };
