import 'react-widgets/dist/css/react-widgets.css';
import { DateTimePicker } from 'react-widgets';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { bodySize, breakpoints, inputSize } from '../../../constants';
import { Icon } from '../../icons';
import { Label, P } from '../../typography';
import dayjsLocalizer from './localizer';

dayjsLocalizer();

const DateTimeInput = ({
  autoFocus,
  disabled,
  label,
  errorMessage,
  enableTime,
  enableDate,
  format,
  isError,
  name,
  onChange,
  onFocus,
  onBlur,
  value,
  placeholder,
}) => (
  <>
    <Label fontWeight="normal" color="greyPrimary" mb="0.2rem" id="date-time-picker-label">
      {label}
    </Label>
    <StyledDateTimePicker
      autoFocus={autoFocus}
      defaultValue={new Date()}
      date={enableDate}
      dateIcon={disabled ? null : <Icon name="schedule" />}
      disabled={disabled}
      format={format}
      id={name}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      aria-labelledby="date-time-picker-label"
      onFocus={onFocus}
      placeholder={placeholder}
      value={value}
      time={enableTime}
      timeIcon={<Icon name="clock" />}
      isError={isError}
    />
    {isError && (
      <P variant={3} color="errorPrimary" mt="0.5rem">
        {errorMessage}
      </P>
    )}
  </>
);

const StyledDateTimePicker = styled(DateTimePicker)`
  font-size: ${bodySize.L3M2S1};
  width: 100%;

  @media (max-width: ${breakpoints.md}) {
    .rw-calendar-popup {
      left: unset;
    }
  }

  .rw-widget-input {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.grey300 : theme.colors.black)};
    padding: 0 0.5rem;

    ::placeholder {
      color: ${({ theme }) => theme.colors.grey300};
    }
  }

  .rw-widget-picker {
    border-radius: 2px;
    min-height: ${inputSize.sm};
    border-color: ${({ theme, disabled, isError, value }) => {
      if (disabled) {
        return theme.colors.grey100;
      }

      if (isError) {
        return theme.colors.errorPrimary;
      }

      if (value) {
        return theme.colors.successPrimary;
      }

      return theme.colors.grey300;
    }};

    @media (min-width: ${breakpoints.md}) {
      font-size: ${bodySize.L2M1Sx};
      min-height: ${inputSize.md};
    }

    @media (min-width: ${breakpoints.lg}) {
      font-size: ${bodySize.L1MxSx};
      min-height: ${inputSize.lg};
    }
  }

  .rw-widget-picker,
  .rw-widget-input {
    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${({ theme }) => theme.colors.grey100};
      `}

    ${({ isError }) =>
      isError &&
      css`
        background-color: ${({ theme }) => theme.colors.error50};
      `}
  }

  .rw-select-bordered {
    border-color: ${({ theme, disabled, isError, value }) => {
      if (disabled) {
        return theme.colors.grey100;
      }

      if (isError) {
        return theme.colors.errorPrimary;
      }

      if (value) {
        return theme.colors.successPrimary;
      }

      return theme.colors.grey300;
    }};
  }
`;

DateTimeInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date),
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  enableDate: PropTypes.bool,
  enableTime: PropTypes.bool,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
};

DateTimeInput.defaultProps = {
  autoFocus: false,
  disabled: false,
  enableDate: true,
  enableTime: true,
  format: null,
  placeholder: null,
  value: null,
  onBlur: noop,
  onChange: noop,
  onFocus: noop,
  errorMessage: null,
  isError: false,
};

export { DateTimeInput };
