import { get } from 'lodash';
import { rgba } from 'polished';
import { useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { bodySize, breakpoints } from '../../../constants';
import { Label, P } from '../../typography';

const FormikSingleDateInput = ({ autoFocus, label, name, maxDate }) => {
  const { values, touched, errors, setFieldValue, onBlur } = useFormikContext();

  const handleChange = date => {
    setFieldValue(name, date);
  };

  const value = get(values, name);

  const isValid = !!value;
  const hasError = get(touched, name) && get(errors, name);

  return (
    <>
      <InputLabel color="greyPrimary" mb="0.2rem">
        {label}
      </InputLabel>
      <Wrapper isValid={isValid} hasError={hasError}>
        <StyledDatePicker
          autoFocus={autoFocus}
          selected={value}
          onChange={handleChange}
          isValid={isValid}
          hasError={hasError}
          maxDate={maxDate}
          onBlur={onBlur}
          dateFormat="dd/MM/yyyy"
        />
      </Wrapper>
      {hasError && (
        <P variant={3} color="periRed" mt="0.2rem">
          {errors[name]}
        </P>
      )}
    </>
  );
};

const StyledDatePicker = styled(DatePicker)`
  border-radius: 2px;
  border: none;
  font-size: ${bodySize.L3M2S1};
  padding: 0.5rem;
  width: 100%;
  border: 1px solid ${({ theme, isValid }) => (isValid ? theme.colors.successPrimary : theme.colors.grey300)};

  :active,
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.info400};
    box-shadow: 0px 0px 6px 0px ${({ theme }) => rgba(theme.colors.info400, 0.25)};
  }

  :disabled {
    border: 1px solid ${({ theme }) => theme.colors.grey100};
    background-color: ${({ theme }) => theme.colors.grey100};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.grey300};
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: ${bodySize.L2M1Sx};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${bodySize.L1MxSx};
  }

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.errorPrimary};
      background-color: ${({ theme }) => theme.colors.error50};
    `}
`;

const InputLabel = styled(Label)`
  font-weight: normal;
`;

const Wrapper = styled.div`
  position: relative;
`;

FormikSingleDateInput.propTypes = {
  autoFocus: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  maxDate: PropTypes.instanceOf(Date),
};

FormikSingleDateInput.defaultProps = {
  autoFocus: false,
  maxDate: undefined,
};

export { FormikSingleDateInput };
