import { space } from 'styled-system';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Switch from 'react-switch';

import { colors } from '../../../constants/colors';
import { Icon } from '../../icons';
import { P } from '../../typography';

const ToggleSwitch = ({ name, label, hasIcons, checked, onChange, ...rest }) => (
  <SwitchContainer htmlFor={name} key={name} {...rest}>
    <Switch
      id={name}
      name={name}
      onChange={onChange}
      checked={checked}
      height={14}
      width={34}
      handleDiameter={20}
      offColor={colors.grey300}
      onColor={colors.grey300}
      offHandleColor={colors.grey100}
      onHandleColor={colors.black}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 1px rgba(0, 0, 0, 0.04), 0px 2px 1px rgba(0, 0, 0, 0.03), 0px 1px 3px rgba(0, 0, 0, 0.07)"
      activeBoxShadow="0px 1px 1px rgba(0, 0, 0, 0.11), 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.14)"
      checkedHandleIcon={
        hasIcons ? (
          <Handle>
            <Icon name="tick" width="18px" height="18px" fill={colors.white} />
          </Handle>
        ) : null
      }
      uncheckedHandleIcon={
        hasIcons ? (
          <Handle>
            <Icon name="cross" width="18px" height="18px" fill={colors.grey300} />
          </Handle>
        ) : null
      }
    />
    {label && (
      <P variant={4} m={0} ml="12px">
        {label}
      </P>
    )}
  </SwitchContainer>
);

const SwitchContainer = styled.label`
  ${space}
  display: inline-flex;
  align-items: center;
  user-select: none;

  :hover {
    cursor: pointer;
  }
`;

const Handle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

ToggleSwitch.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  hasIcons: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

ToggleSwitch.defaultProps = {
  label: null,
  hasIcons: false,
};

export { ToggleSwitch };
