import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../../grid';
import { breakpoints } from '../../../constants';

const Checkbox = ({ name, label, onChange, checked, onBlur }) => {
  return (
    <CheckboxContainer>
      <label htmlFor={name}>
        <CheckboxInput id={name} name={name} type="checkbox" checked={checked} onBlur={onBlur} onChange={onChange} />
        <Flex>
          <Box>
            <StyledCheckbox checked={checked}>
              <svg viewBox="0 0 24 24" fill="none" stroke={checked ? 'white' : 'transparent'}>
                <polyline points="20 6 9 17 4 12" strokeWidth="2px" />
              </svg>
            </StyledCheckbox>
          </Box>
          {label && <StyledLabel ml="0.5rem">{label}</StyledLabel>}
        </Flex>
      </label>
    </CheckboxContainer>
  );
};

const StyledLabel = styled(Box)`
  cursor: pointer;
  display: inline-block;
`;

const CheckboxContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
`;

const StyledCheckbox = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${({ checked, theme }) => (checked ? theme.colors.black : theme.colors.white)};
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  transition: all 150ms;

  @media (min-width: ${breakpoints.md}) {
    width: 24px;
    height: 24px;
  }

  :hover {
    border-color: ${({ theme }) => theme.colors.black};
  }
`;

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  label: null,
  onBlur: undefined,
  onChange: undefined,
};

export { Checkbox };
