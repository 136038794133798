import { rgba } from 'polished';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { bodySize, breakpoints, inputSize } from '../../../constants';
import { Box } from '../../grid';
import { Icon } from '../../icons';
import { Label, P } from '../../typography';

const SelectFieldIcon = styled(Icon)`
  pointer-events: none;
  transform: translateY(-50%);
`;

const SelectInput = ({
  autoFocus,
  disabled,
  errorMessage,
  isError,
  label,
  name,
  onChange,
  onFocus,
  onBlur,
  options,
  placeholder,
  required,
  value,
  width,
  testId,
}) => (
  <>
    <Label as="label" htmlFor={name} fontWeight="normal" color="greyPrimary" mb="0.2rem" data-testid={testId}>
      {label}
    </Label>
    <SelectFieldWrapper width={width}>
      <SelectField
        autoFocus={autoFocus}
        disabled={disabled}
        id={name}
        isError={isError}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        required={required}
        width={width}
        value={value || placeholder}
      >
        {placeholder && <option disabled>{placeholder}</option>}
        {options.map(item => (
          <option key={item.value} value={item.value}>
            {item.text}
          </option>
        ))}
      </SelectField>
      <SelectFieldIcon
        name="chevron"
        position="absolute"
        height="24px"
        right="15px"
        top="50%"
        width="24px"
        fill="grey500"
      />
    </SelectFieldWrapper>
    {isError && (
      <P variant={3} color="periRed" mt="0.2rem">
        {errorMessage}
      </P>
    )}
  </>
);

const SelectFieldWrapper = styled(Box)`
  position: relative;
  width: ${({ width }) => width};
`;

const SelectField = styled.select`
  appearance: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  border: 1px solid
    ${({ theme, value, placeholder }) =>
      value && value !== placeholder ? theme.colors.successPrimary : theme.colors.grey300};
  cursor: pointer;
  font-size: ${bodySize.L3M2S1};
  padding: 0.5rem;
  width: ${({ width }) => width};
  min-height: ${inputSize.sm};
  color: ${({ theme, placeholder, value }) => (value === placeholder ? theme.colors.grey300 : theme.colors.black)};

  :active,
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.info400};
    box-shadow: 0px 0px 6px 0px ${({ theme }) => rgba(theme.colors.info400, 0.25)};
  }

  :disabled {
    border: 1px solid ${({ theme }) => theme.colors.grey100};
    background-color: ${({ theme }) => theme.colors.grey100};
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: ${bodySize.L2M1Sx};
    min-height: ${inputSize.md};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${bodySize.L1MxSx};
    min-height: ${inputSize.lg};
  }

  ${({ isError }) =>
    isError &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.errorPrimary} !important;
      box-shadow: none !important;
      background-color: ${({ theme }) => theme.colors.error50};
    `}
`;

SelectInput.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, text: PropTypes.string })).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  testId: PropTypes.string,
  value: PropTypes.string.isRequired,
  width: PropTypes.string,
};

SelectInput.defaultProps = {
  autoFocus: false,
  disabled: false,
  errorMessage: null,
  isError: false,
  label: null,
  onBlur: undefined,
  onFocus: noop,
  placeholder: null,
  required: false,
  testId: null,
  width: '100%',
};

export { SelectInput };
