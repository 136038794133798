import { get } from 'lodash';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { Checkbox } from '../checkbox';

const FormikCheckbox = ({ name, label, optionValues }) => {
  const { values, setFieldValue, handleBlur } = useFormikContext();

  const setChecked = checked => {
    if (checked) {
      setFieldValue(name, get(optionValues, 'on', checked));
    } else {
      setFieldValue(name, get(optionValues, 'off', checked));
    }
  };

  const value = get(values, name);
  const checked = optionValues ? optionValues.on === value : !!value;

  return (
    <Checkbox
      name={name}
      label={label}
      checked={get(values, name, false)}
      value={checked}
      onBlur={handleBlur}
      onChange={event => setChecked(event.target.checked)}
    />
  );
};

FormikCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  optionValues: PropTypes.shape({ on: PropTypes.any, off: PropTypes.any }),
};

FormikCheckbox.defaultProps = {
  label: null,
  optionValues: null,
};

export { FormikCheckbox };
