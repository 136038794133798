import { INPUT_AUTOCOMPLETE, INPUT_TYPE } from '@nandosaus/constants';
import { isUndefined, noop, values } from 'lodash';
import { rgba } from 'polished';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { bodySize, breakpoints, inputSize } from '../../../constants';
import { Flex } from '../../grid';
import { Label, P } from '../../typography';

const INPUT_TYPE_MAPPING = {
  [INPUT_TYPE.CREDIT_CARD]: 'text',
  [INPUT_TYPE.EMAIL]: 'email',
  [INPUT_TYPE.EXPIRY_DATE]: 'text',
  [INPUT_TYPE.NUMBER]: 'number',
  [INPUT_TYPE.NUMERIC]: 'number',
  [INPUT_TYPE.PASSWORD]: 'password',
  [INPUT_TYPE.PHONE]: 'tel',
};

const TextInput = ({
  autoComplete,
  autoFocus,
  errorMessage,
  defaultValue,
  disabled,
  isError,
  label,
  leftIcon,
  maxLength,
  name,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  required,
  type,
  value,
  width,
  onKeyDown,
}) => {
  return (
    <>
      {label && (
        <Label as="label" fontWeight="normal" htmlFor={name} color="greyPrimary" mb="0.2rem">
          {label}
        </Label>
      )}
      <TextWrapper isError={isError} value={value} width={width} disabled={disabled} alignItems="center">
        {leftIcon && leftIcon}
        <TextField
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          defaultValue={isUndefined(value) ? defaultValue : undefined}
          disabled={disabled}
          id={name}
          isError={isError}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          required={required}
          rows={3}
          type={INPUT_TYPE_MAPPING[type] || 'text'}
          value={value}
          onKeyDown={onKeyDown}
        />
      </TextWrapper>
      {isError && errorMessage !== null && (
        <P variant={3} color="errorPrimary" mt="0.5rem">
          {errorMessage}
        </P>
      )}
    </>
  );
};

const TextWrapper = styled(Flex)`
  min-height: ${inputSize.sm};
  border-radius: 2px;

  :focus-within {
    box-shadow: 0px 0px 6px 0px
      ${({ theme, isError }) => rgba(isError ? theme.colors.errorPrimary : theme.colors.info400, 0.25)};
    border: 1px solid ${({ isError, theme }) => (isError ? theme.colors.errorPrimary : theme.colors.info400)};
  }

  border: 1px solid
    ${({ theme, isError, disabled, value }) => {
      if (disabled) {
        return theme.colors.grey100;
      }

      if (isError) {
        return theme.colors.errorPrimary;
      }

      if (value) {
        return theme.colors.successPrimary;
      }

      return theme.colors.grey300;
    }};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${bodySize.L2M1Sx};
    min-height: ${inputSize.md};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${bodySize.L1MxSx};
    min-height: ${inputSize.lg};
  }

  ${({ isError, theme }) =>
    isError &&
    css`
      background-color: ${theme.colors.error50};
      box-shadow: none !important;

      input {
        background-color: ${theme.colors.error50};
      }
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.grey300} !important;
      background-color: ${theme.colors.grey100};
      box-shadow: none !important;

      input {
        background-color: ${theme.colors.grey100};
      }
    `}
`;

const TextField = styled.input`
  min-height: ${inputSize.sm};
  border: none;
  font-size: ${bodySize.L3M2S1};
  padding: 0.5rem;
  width: 100%;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.grey300 : theme.colors.black)};

  ::placeholder {
    color: ${({ theme }) => theme.colors.grey300};
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: ${bodySize.L2M1Sx};
    min-height: ${inputSize.md};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${bodySize.L1MxSx};
    min-height: ${inputSize.lg};
  }
`;

TextInput.propTypes = {
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.oneOf([...values(INPUT_AUTOCOMPLETE), 'off']),
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  label: PropTypes.string,
  leftIcon: PropTypes.node,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    INPUT_TYPE.CREDIT_CARD,
    INPUT_TYPE.EMAIL,
    INPUT_TYPE.EXPIRY_DATE,
    INPUT_TYPE.NUMBER,
    INPUT_TYPE.NUMERIC,
    INPUT_TYPE.PASSWORD,
    INPUT_TYPE.PHONE,
    INPUT_TYPE.TEXT,
  ]),
  value: PropTypes.string,
  width: PropTypes.string,
  onKeyDown: PropTypes.func,
};

TextInput.defaultProps = {
  autoFocus: false,
  autoComplete: 'off',
  defaultValue: undefined,
  disabled: false,
  errorMessage: null,
  isError: false,
  label: null,
  leftIcon: null,
  maxLength: null,
  onFocus: noop,
  onBlur: noop,
  onChange: undefined,
  placeholder: null,
  required: false,
  type: INPUT_TYPE.TEXT,
  value: undefined,
  width: '100%',
  onKeyDown: noop,
};

export { TextInput };
